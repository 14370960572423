<template>
  <slot />
  <SiteOverlay />
</template>

<script setup>
  import SiteOverlay from '@/components/SiteOverlay'

  onMounted(() => {
    useHead({
      link: [{
        href: 'https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&display=swap',
        rel: 'stylesheet'
      }]
    })
  })
</script>

<style lang="scss">

@use '@/sections/SitePreHeader/index.scss' as preHeader;

@use '@/components/SiteButton/index.scss' as button;
@use '@/components/SiteLogoTitle/index.scss' as logoTitle;
@use '@/sections/SiteHeader/SiteBurger/index.scss' as headerBurger;
@use '@/sections/SiteHeader/SiteMenu/index.scss' as headerMenu;
@use '@/sections/SiteHeader/SiteMenuButton/index.scss' as headerMenuButton;
@use '@/sections/SiteHeader/SiteMobileMenu/index.scss' as headerMobileMenu;
@use '@/sections/SiteHeader/index.scss' as header;
@use '@/sections/home/SiteHeroBanner/index.scss' as heraBanner;
@use '@/sections/home/SitePitch/index.scss' as pitch;

$desktop-breakpoint: 990px;
$tablet-breakpoint: 672px;

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  &.blocked {

    #__nuxt {
      overflow: hidden;
      height: 100vh;
      position: relative;
    }
  }

  .site-section-title {
    font-size: 40px;
    font-weight: 500;

    @media screen and (min-width: $desktop-breakpoint) {
      font-size: 60px;
      font-weight: 600;
    }
  }
}
</style>